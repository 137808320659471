.social-media {
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  white-space: nowrap;
}

.social-media:hover {
  color: #6606bb;
  cursor: pointer;
}

.mobile-icon {
  width: 40px;
  height: 40px;
}
