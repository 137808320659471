.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 600px;
  flex-basis: auto;
  font-family: "Poppins";
  box-sizing: border-box;
}

.header {
  flex: 15%;
  height: 200px;
  overflow: hidden;
  border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%;
  background-image: url("../assets/images/youtube-banner-cropped.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-l {
  flex: 20%;
  flex-basis: 60px;
  max-height: 60px;
  display: flex;
  text-align: center;
  justify-content: space-around;
}

.footer-sm {
  flex: 20%;
  flex-basis: 60px;
  max-height: 60px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-evenly;
  display: none;
}

.nav {
  background-color: #f2f2f2;
  height: 50px;
  display: flex;
  font-size: 18px;
  align-items: center;
}

.nav-item {
  background-color: #f2f2f2;
  height: 50px;
  display: flex;
  font-size: 18px;
  align-items: center;
  padding: 0px 18px;
  color: grey;
  box-sizing: border-box;
}

.nav-item:hover {
  background-color: #dcdcdc;
  color: #6606bb;
  box-shadow: inset 0 -3px 0 0 #6606bb;
}

.content {
  flex: 65%;
  display: flex;
  overflow: auto;
  justify-content: center;
}

.header-image {
  width: 100%;
  height: 100%;
}

.header-image-mobile {
  width: 480px;
  height: 320px;
}

.header-l {
  display: block;
}

.header-sm {
  background-color: black;
  background-image: url("../assets/images/denward-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  display: none;
}

@media screen and (max-width: 1080px) {
  .header-l,
  .footer-l {
    display: none;
  }

  .header-l:hover,
  .header-sm:hover {
    cursor: pointer;
  }

  .header-sm {
    display: block;
  }

  .footer-sm {
    padding: 15px 0;
    display: flex;
  }

  .content {
    margin: 5px;
  }
}
