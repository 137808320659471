.booking-container {
  overflow-y: auto;
  width: 60%;
  border: 1px solid #eeeee4;
  border-radius: 10px;
  margin: 36px 72px;
  padding: 18px;
}

.booking-content {
  width: 75%;
}

fieldset {
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  border: none;
  border-radius: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

form {
  display: flex;
  flex-direction: column;
}

.form-input {
  display: flex;
  flex-direction: column;
}

#fs-frm input,
textarea,
select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.75em 1rem;
  margin-bottom: 1.5rem;
}

#fs-frm label {
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 5px;
  -webkit-appearance: none;
  appearance: none;
}

#submit-button:hover {
  cursor: pointer;
}

@media screen and (max-width: 1080px) {
  .booking-container {
    width: 100%;
    margin: 0;
  }

  #fs-frm input,
  textarea,
  #event,
  select {
    width: 100%;
  }
}
