.faqs-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 60%;
  border: 1px solid #eeeee4;
  border-radius: 10px;
  margin: 36px 72px;
  padding: 18px;
}

.faqs-content {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1080px) {
  .faqs-container {
    margin: 0;
    width: 100%;
  }
}
