.about-us-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 60%;
  border: 1px solid #eeeee4;
  border-radius: 10px;
  margin: 36px 72px;
  padding: 18px;
}

.about-us-image {
  width: 100%;
  min-height: 175px;
  margin: 18px 0px;
  background-image: url("../assets/images/aboutus.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us-content {
  margin-bottom: 18px;
}

@media screen and (max-width: 1080px) {
  .about-us-container {
    margin: 0;
    width: 100%;
  }
}
