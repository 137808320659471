.service-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 60%;
  border: 1px solid #eeeee4;
  border-radius: 10px;
  margin: 36px 72px;
  padding: 18px;
}

.services-content {
  display: flex;
  flex-direction: column;
}

.services-content-columns {
  display: flex;
  flex-direction: row;
}

.services-content-column-header {
  flex: 1;
  text-align: center;
}

.services-content-column {
  flex: 1;
  align-items: center;
  padding-left: 18px;
  padding-bottom: 18px;
}

.services-content-column:last-child,
.services-content-column-header:last-child {
  border-left: 1px dashed #eeeee4;
}

.description-span {
  margin-bottom: 18px;
}

ul {
  margin: 0;
  margin-bottom: 18px;
}

.additional-info {
  font-size: smaller;
}

.services-sm {
  display: none;
}

@media screen and (max-width: 1080px) {
  .service-container {
    width: 100%;
    margin: 0;
  }

  .services-l,
  .header-l {
    display: none;
  }

  .services-content-column {
    padding-left: 0;
  }

  .services-sm {
    display: flex;
    flex-direction: column;
  }

  .services-content-column:last-child {
    border-left: none;
    border-top: 1px dashed #eeeee4;
  }
}
