.services-container {
  display: flex;
  flex-direction: column;
  /* min-height: 500px; */
  justify-content: center;
  flex-basis: auto;
  width: 100%;
}

.services-row {
  /* width: 100vw; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-basis: auto;
}

.services-icon {
  height: 200px;
  flex: 1;
  background-color: #edeaf8;
  border-radius: 60px;
  margin: 15px 40px;
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 400;
}

.services-icon:hover {
  color: white;
  background-image: url("../assets/images/dj-icon.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  font-weight: 600;
}

@media screen and (max-width: 1080px) {
  .services-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100vw;
    flex-basis: auto;
  }

  .services-container {
    display: flex;
    justify-content: center;
  }

  .services-icon {
    height: 100px;
    background-color: #edeaf8;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
    font-weight: 400;
  }
}
